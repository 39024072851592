import $ from 'jquery';

$(document).on('change', '.js-beforeunload', ({ currentTarget }) => {
  const $form = $(currentTarget);
  const message = '未保存のデータがありますが、本当に閉じますか？';
  const onBeforeunload = () => message;

  $(window).on('beforeunload', onBeforeunload);
  $form.on('submit', () => $(window).off('beforeunload', onBeforeunload));

  $form.on('click', '.js-unbind-beforeunload', () => $(window).off('beforeunload', onBeforeunload));
});
