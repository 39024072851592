import $ from 'jquery';

function enableSelect2OnSectionSelect() {
  const $sectionSelect = $('.js-select2-thanks-card-section').select2({
    allowClear: true,
    placeholder: '所属',
  });
  $sectionSelect.on('select2:select select2:clear', (ev) => {
    const $form = $(ev.currentTarget).closest('form');
    $form.submit();
  });
}

$(() => {
  enableSelect2OnSectionSelect();
});
