import $ from 'jquery';

$(document).on('click', '.js-entry-publish', ({ currentTarget }) => {
  $('#board_entry_publication_type').val('public');
  $(currentTarget).closest('form').submit();
  return false;
});

$(() => {
  $('.js-shortcut-tabs').tabs({
    beforeActivate(event, ui) {
      const url = ui.newTab.find('a').attr('href');
      if (url) {
        window.location.href = url;
        return false;
      }
      return true;
    },
    active: 0,
  });
});

// テンプレート機能
const loadTemplate = (url) => {
  $.ajax({
    type: 'GET',
    url,
    dataType: 'json',
  }).done((res) => {
    $('#board_entry_title').val(res.title);
    $('#board_entry_contents').val(res.content);
    $('#board_entry_place_category_id').val(res.entry_category_id);
  }).fail((res) => {
    console.error('failed to ajax request');
  });
};

$(document).on('change', 'select#template-lists', ({ currentTarget }) => {
  const url = $(currentTarget).children('option:selected').val();
  const content = $('#board_entry_contents').val();
  if (url) {
    if (content) {
      if (confirm('編集中の記事が上書きされます\nよろしいですか？')) {
        loadTemplate(url);
      }
    } else {
      loadTemplate(url);
    }
  }
});

const insertToHikiEditor = (text) => {
  $('#board_entry_contents').val($('#board_entry_contents').val() + text);
};

$(document).on('click', '.insert_file_operation', (event) => {
  event.preventDefault();
  if ($(event.currentTarget).hasClass('image')) {
    insertToHikiEditor(`\n{{ attach(${$(event.currentTarget).attr('attach_id')},240) }}`);
  } else {
    insertToHikiEditor(`\n{{ attach(${$(event.currentTarget).attr('attach_id')}) }}`);
  }
});

$(document).on('click', '#js-change-place-category', (event) => {
  event.preventDefault();
  $(event.currentTarget).closest('#js-root-place-category').find('.js-place-category-select-wrapper').removeClass('d-none');
  $(event.currentTarget).hide();
});

$(document).on('change', '.js-place-category-select-wrapper select', (event) => {
  event.preventDefault();
  const { currentTarget } = event;
  const placeCategoryId = $(currentTarget).find(':selected').val();
  $.ajax({
    data: {
      'board_entry[place_category_id]': placeCategoryId,
    },
    dataType: 'script',
    type: 'post',
    url: $(currentTarget).attr('target_url'),
  });
});

$(document).on('click', '.js-toggle-edit-comment-form', (event) => {
  event.preventDefault();
  const { currentTarget } = event;
  const $boardEntryComment = $(currentTarget).closest('.board_entry_comment');
  $boardEntryComment.find('> .edit_comment_form').toggleClass('d-none');
  $boardEntryComment.find('> .hiki_style').toggle('fast');
  $boardEntryComment.find('> .attaches_wrapper').toggle('fast');
});

$(document).on('click', '.js-toggle-reply-comment-form', (event) => {
  event.preventDefault();
  const { currentTarget } = event;
  const $form = $(currentTarget).closest('.board_entry_comment').find('.reply_comment_form:first');
  $form.find('textarea:first').val('');
  $form.find('input[type=submit]:first').attr('disabled', true);
  $form.toggleClass('d-none');
});

$(document).on('click', '#open_preview', () => {
  $('.tabs_wrapper').tabs({ active: 1 });
});

$(document).on('click', '#close_preview', () => {
  $('.tabs_wrapper').tabs({ active: 0 });
});

$(document).on('ajax:success', '.destroy_draft_trigger', ({ currentTarget }) => {
  $('#private_entry_' + $(currentTarget).attr('data-entry-id')).remove();
});
