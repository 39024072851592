import $ from 'jquery';

$(document).on('click', '#js-user-navigation-list', () => {
  $('#more_menu').toggle();
});

$(document).on('click', '.js-unread-only-link', (event) => {
  event.preventDefault();
  const { currentTarget } = event;
  const dataHref = $(currentTarget).attr('data-href');
  if (dataHref) {
    window.location.href = dataHref;
  }
});
