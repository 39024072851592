import $ from 'jquery';

$(document).on('click', '#js-click-announcement', (event) => {
  event.preventDefault();
  const { currentTarget } = event;
  $(currentTarget).closest('#js-announcement').find('.description a')[0].click();
});

$(() => {
  if ($('#js-announcement').length > 0) {
    const $target = $('#js-announcement');
    $target.dialog({ title: $target.data('title') });
  }
});
