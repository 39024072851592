import Readmore from 'readmore-js';
import $ from 'jquery';

const autoReadmore = () => {
  const $target = $('.js-readmore');
  if ($target.length === 0) return;
  const height = $target.data('collapsed-height') || 150;

  new Readmore('.js-readmore', {
    speed: 200,
    collapsedHeight: height,
    moreLink: (element) => {
      const moreText = element.dataset.moreText || '[全て表示]';
      return `<a href='#' class='readmore-js-toggle'>${moreText}</a>`;
    },
    lessLink: (element) => {
      const lessText = element.dataset.lessText || '[閉じる]';
      return `<a href='#' class='readmore-js-toggle'>${lessText}</a>`;
    },
  });
};

document.addEventListener('DOMContentLoaded', () => {
  autoReadmore();
});
