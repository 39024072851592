import $ from 'jquery';
import _ from 'lodash';
import Chart from 'chart.js/auto';

const color1 = '#4BB2C5';
const color2 = '#EAA228';
const color3 = '#C5B47F';

const renderAccessGraph = () => {
  const [dates, yesterdayValues, recentValues] = Array.from(_.unzip($('canvas#js-accesses-graph').data('chart')));
  const data = {
    labels: dates,
    datasets: [
      {
        fill: false,
        borderColor: color1,
        pointBackgroundColor: color1,
        backgroundColor: color1,
        label: '前日のアクセス数',
        data: yesterdayValues,
      }, {
        fill: false,
        borderColor: color2,
        pointBackgroundColor: color2,
        backgroundColor: color2,
        label: '過去10日間以内のアクセス数）',
        data: recentValues,
      },
    ],
  };

  const ctx = document.getElementById('js-accesses-graph').getContext('2d');
  new Chart(ctx, {
    type: 'line',
    data,
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              min: 0,
            },
          },
        ],
      },
    },
  });
};

const renderEntriesGraph = () => {
  const [dates, usersValues, communitiesValues, groupsValues] = Array.from(_.unzip($('canvas#js-entries-graph').data('chart')));
  const data = {
    labels: dates,
    datasets: [
      {
        fill: false,
        borderColor: color1,
        pointBackgroundColor: color1,
        backgroundColor: color1,
        label: 'つぶやき/記事の投稿記事数',
        data: usersValues,
      }, {
        fill: false,
        borderColor: color2,
        pointBackgroundColor: color2,
        backgroundColor: color2,
        label: '公開コミュニティでの投稿数',
        data: communitiesValues,
      }, {
        fill: false,
        borderColor: color3,
        pointBackgroundColor: color3,
        backgroundColor: color3,
        label: '非公開コミュニティでの投稿数',
        data: groupsValues,
      },
    ],
  };

  const ctx = document.getElementById('js-entries-graph').getContext('2d');
  new Chart(ctx, {
    type: 'line',
    data,
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              min: 0,
            },
          },
        ],
      },
    },
  });
};

const renderCommentsGraph = () => {
  const [dates, usersValues, communitiesValues, groupsValues] = Array.from(_.unzip($('canvas#js-comments-graph').data('chart')));
  const data = {
    labels: dates,
    datasets: [
      {
        fill: false,
        borderColor: color1,
        pointBackgroundColor: color1,
        backgroundColor: color1,
        label: 'つぶやき/記事へのコメント数',
        data: usersValues,
      }, {
        fill: false,
        borderColor: color2,
        pointBackgroundColor: color2,
        backgroundColor: color2,
        label: '公開コミュニティの記事へのコメント数',
        data: communitiesValues,
      }, {
        fill: false,
        borderColor: color3,
        pointBackgroundColor: color3,
        backgroundColor: color3,
        label: '非公開コミュニティの記事へのコメント数',
        data: groupsValues,
      },
    ],
  };

  const ctx = document.getElementById('js-comments-graph').getContext('2d');
  new Chart(ctx, {
    type: 'line',
    data,
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              min: 0,
            },
          },
        ],
      },
    },
  });
};

const renderPostsGraph = () => {
  const [dates, entryUsersValues, commentUsersValues, usersAtMonthValues] = Array.from(_.unzip($('canvas#js-posts-graph').data('chart')));
  const data = {
    labels: dates,
    datasets: [
      {
        fill: false,
        borderColor: color1,
        pointBackgroundColor: color1,
        backgroundColor: color1,
        label: '記事を投稿したことのあるユーザ数',
        data: entryUsersValues,
      }, {
        fill: false,
        borderColor: color2,
        pointBackgroundColor: color2,
        backgroundColor: color2,
        label: 'コメントを投稿したことのあるユーザ数',
        data: commentUsersValues,
      }, {
        fill: false,
        borderColor: color3,
        pointBackgroundColor: color3,
        backgroundColor: color3,
        label: '1ヶ月以内に記事かコメントを投稿したユーザ数',
        data: usersAtMonthValues,
      },
    ],
  };

  const ctx = document.getElementById('js-posts-graph').getContext('2d');
  new Chart(ctx, {
    type: 'line',
    data,
    options: {
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              min: 0,
            },
          },
        ],
      },
    },
  });
};

document.addEventListener('DOMContentLoaded', () => {
  if ($('canvas#js-accesses-graph').length !== 0) {
    renderAccessGraph();
  }

  if ($('canvas#js-entries-graph').length !== 0) {
    renderEntriesGraph();
  }

  if ($('canvas#js-comments-graph').length !== 0) {
    renderCommentsGraph();
  }

  if ($('canvas#js-posts-graph').length !== 0) {
    renderPostsGraph();
  }
});
