import $ from 'jquery';

// ダイアログの枠外クリックでクローズ
// 使ってないかも。のちのち削減したい
$(document).on('click', '.ui-widget-overlay', () => {
  $('div:ui-dialog:visible').dialog('close');
});

// エンターキーでのsubmitを無効化
$(document).on('keypress', '.js-invalidate-enter-submit input:not([type="button"]):not([type="submit"])', (e) => {
  if (e.keyCode === 13) return false;
});
