import $ from 'jquery';

$(document).on('click', '#check_all_link', (event) => {
  event.preventDefault();
  const checked = $(event.currentTarget).hasClass('checked');
  if (checked) {
    $('.multicheck').prop('checked', '');
    $(event.currentTarget).removeClass('checked');
  } else {
    $('.multicheck').prop('checked', 'checked');
    $(event.currentTarget).addClass('checked');
  }
});

$(document).on('click', '#js-toggle-import-success-users', (event) => {
  event.preventDefault();
  $('.js-import-success-user').toggle();
});

document.addEventListener('DOMContentLoaded', () => {
  if ($('.js-manage-table-columns').length > 0) {
    const offset = $('.js-manage-table-columns').data('offset') || 0;

    $('.js-manage-table-columns button').each((i, element) => {
      const index = offset + i;

      const $target = $(`.js-table-admin-users th:nth-child(${index + 1}),td:nth-child(${index + 1})`);
      if (!$(element).hasClass('active')) {
        $target.hide();
      }
      $(element).unbind('click');
      $(element).click((event) => {
        if ($(event.currentTarget).hasClass('active')) {
          $(event.currentTarget).removeClass('active');
        } else {
          $(event.currentTarget).addClass('active');
        }
        $target.toggle();
      });
    });
  }
});
