import $ from 'jquery';

$(document).on('click', '.js-expand-comment-attachments', (event) => {
  $(event.currentTarget).closest('form').find('li.attaches').toggleClass('d-none');
});

$(document).on('click', '.js-expand-comment-mention-user-list', (event) => {
  $(event.currentTarget).closest('form').find('li.mentions').toggleClass('d-none');
});

$.fn.checkCharacterCount = function() {
  const $form = $('form.check_char_count');
  const $submit = $form.find('input[type=submit]');
  $submit.prop('disabled', true);
  const $textarea = $form.find('textarea');
  $textarea.on('keydown keyup keypress change', function() {
    const isBlank = $(this).val().length === 0;
    $submit.prop('disabled', isBlank);
  });
};

$(() => {
  if ($('form.check_char_count').length > 0) {
    $('form.check_char_count').checkCharacterCount();
  }
});
