import $ from 'jquery';

$(document).on('click', '.js-fire-telephone-form', () => {
  $('.js-toggle-telephone-form').toggle('slow');
});

// スマホで利用登録時にギャラリー画像をすべて表示
$(document).on('click', '#show_profile_pictures', ({ currentTarget }) => {
  $(".selectable_pictures img").removeClass('hidden');
  $(currentTarget).remove();
});

// メールアドレスの変更チェック
$(document).on('change', '.js-detect-change-email', ({ currentTarget }) => {
  $(currentTarget).css('background', '#FFE4E1');
  if (!$("span.changed_notification")[0]) {
    $(currentTarget).after("<span class='changed_notification'>* 変更されていますがよろしいですか？</span>");
  }
});

$(document).on('click', '#js-toggle-joined-communities', (event) => {
  event.preventDefault();
  $('.participated_communities .community').removeClass('hidden');
  $(event.currentTarget).remove();
});

$(() => {
  if ($('#js-load-about-this-site').length !== 0) {
    $('#js-load-about-this-site').load('/footer/about_this_site');
  }
  if ($('#js-load-rule').length !== 0) {
    $('#js-load-rule').load('/footer/terms_of_use');
  }
});
