import $ from 'jquery';

$(document).on('click', '#js-preview-csv-btn', (event) => {
  const { currentTarget } = event;
  const $form = $('.js-preview-csv-form');
  const formData = new FormData($form.get(0));
  const url = currentTarget.dataset.url;
  $.ajax({
    type: 'POST',
    url: url,
    dataType: 'script',
    data: formData,
    cache: false,
    contentType: false,
    processData: false,
  });
});
