import $ from 'jquery';

const fireAjaxPopover = () => {
  $(document).on('mouseenter', '.js-ajax-popover', (event) => {
    event.preventDefault();
    const { currentTarget } = event;

    // Memo: すべてのpopoverを閉じる
    $('.js-ajax-popover').popover('hide');

    $.ajax({
      url: $(currentTarget).data('preload'),
      type: 'GET',
    }).done((res) => {
      $(currentTarget).popover({
        content: res,
        html: true,
        sanitize: false,
      }).popover('show');
    });
  });

  $(document).on('mouseleave', '.popover', (event) => {
    event.preventDefault();
    const { currentTarget } = event;
    const target = $(currentTarget).attr('id');
    $(`[aria-describedby=${target}`).popover('hide');
  });
};
fireAjaxPopover();

$(document).popover({
  selector: '.js-popover',
});
