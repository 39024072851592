import $ from 'jquery';

const enableTopicAutoPager = () => {
  const $autoLoader = $('.auto_loader');
  $.autopager({
    content: '.topic',
    autoLoad: false,
    start() {
      $autoLoader.show();
    },
    load() {
      $autoLoader.hide();
    },
  });
};

document.addEventListener('DOMContentLoaded', () => {
  $('.js-autoload').each(function() {
    enableTopicAutoPager();
    $.autopager.autoLoad(true);
  });
});
