import $ from 'jquery';

$(document).on('mouseenter', '#js-select-order', ({ currentTarget }) => {
  $(currentTarget).find('.select-order').show();
});
$(document).on('mouseleave', '#js-select-order', ({ currentTarget }) => {
  $(currentTarget).find('.select-order').hide();
});

$(document).on('change', '.js-change-privacy', ({ currentTarget }) => {
  if (currentTarget.value === 'OPEN') {
    alert(currentTarget.dataset.alert);
  }
});

$(document).on('change', '.js-select-image-category', (event) => {
  event.preventDefault();
  if (event.currentTarget.value === 'gallery') {
    $('#js-select-gallery input').attr('disabled', false);
    $('#js-select-gallery').show();
    $('#js-select-upload input').attr('disabled', true);
    $('#js-select-upload').hide();
  } else {
    $('#js-select-upload input').attr('disabled', false);
    $('#js-select-upload').show();
    $('#js-select-gallery input').attr('disabled', true);
    $('#js-select-gallery').hide();
  }
});

$(document).on('click', '.js-community-entry-search', () => {
  $('.community__entry-search').toggle('slow');
});

$(() => {
  if ($('#js-widget-community-activities').length !== 0) {
    $.ajax({
      type: 'GET',
      url: $('#js-widget-community-activities').data('url'),
      dataType: 'script',
    });
  }
});
