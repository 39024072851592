import $ from 'jquery';

$(document).on('click', 'input[type=checkbox]#settings_enable_user_lock', ({ currentTarget }) => {
  if ($(currentTarget).is(':checked')) {
    $('#settings_user_lock_trial_limit').removeAttr('disabled');
    $('label[for=settings_user_lock_trial_limit]').css('color', '#555');
  } else {
    $('#settings_user_lock_trial_limit').attr('disabled', 'disabled');
    $('label[for=settings_user_lock_trial_limit]').css('color', '#999');
  }
});

$(document).on('click', 'input[type=checkbox]#settings_enable_password_periodic_change', ({ currentTarget }) => {
  if ($(currentTarget).is(':checked')) {
    $('#settings_password_change_interval').removeAttr('disabled');
    $('label[for=settings_password_change_interval]').css('color', '#555');
    $('#reset_passwords').show();
  } else {
    $('#settings_password_change_interval').attr('disabled', 'disabled');
    $('label[for=settings_password_change_interval]').css('color', '#999');
    $('#reset_passwords').hide();
  }
});
