import $ from 'jquery';

$(document).on('click', '#show_communities', () => {
  $('.hide_communities').show();
  $('li.see_all_communities').hide();
});

// 遅延ロード
const lazyLoad = (url) => {
  $.ajax({
    type: 'GET',
    url,
    dataType: 'script',
  });
};

const focusInTweet = ({ currentTarget }) => {
  $(currentTarget).parent().children('.hidden_options').show('slow');
  $(currentTarget).parent().children('.tweet_submit').show('slow');
  $('#js-post-attention').show('slow');
};

const focusOutTweet = () => {
  $('#js-post-attention').hide('slow');
};

const getNotificationsCount = (targetUrl, callback) => {
  $.ajax({
    method: 'get',
    url: targetUrl,
    error() {
      const notifications = '0';
      callback(notifications);
    },
    success(data) {
      if (data.count) {
        const notifications = data.count.toString();
        return callback(notifications);
      }
    },
  });
};

document.addEventListener('DOMContentLoaded', () => {
  const lazyLoadIdList = ['#widget_ranking_entries', '#widget_ranking_feedbacks', '#widget_community_users'];

  $.each(lazyLoadIdList, (index, value) => {
    if ($(value).length > 0) {
      lazyLoad($(value).attr('data-url'));
    }
  });

  $('#board_entry_contents').on('focusin', focusInTweet).on('focusout', focusOutTweet);

  if ($('#message-unreads-count').length > 0) {
    const targetUrl = $('#message-unreads-count').attr('data-url');
    getNotificationsCount(targetUrl, (notifications) => {
      if (notifications !== '0') {
        $('#message-unreads-count').append(`<span class='inner'>${notifications}</span>`);
      }
    });
  }

  if ($('#notification-count').length > 0) {
    const targetUrl = $('#notification-count').attr('data-url');
    getNotificationsCount(targetUrl, (notifications) => {
      if (notifications !== '0') {
        $('#notification-count').append(`<span class='inner'>${notifications}</span>`);
      }
    });
  }
});
