import $ from 'jquery';

$(document).on('keyup', '.js-change-category-name', ({ currentTarget }) => {
  const $root = $(currentTarget).closest('.js-root-category');
  const destroyCheckbox = $root.find('.js-destroy-category')[0];
  const $destroyText = $root.find('.js-destroy-text');

  if ($(currentTarget).val() === '') {
    destroyCheckbox.checked = true;
    $destroyText.show();
  } else {
    destroyCheckbox.checked = false;
    $destroyText.hide();
  }
});
