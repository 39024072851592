import $ from 'jquery';
import toastr from 'toastr';
import copy from 'clipboard-copy';

$(document).on('click', '.js-copy-clipboard', (event) => {
  event.preventDefault();
  const { currentTarget } = event;
  copy(currentTarget.dataset.clipboardText);
  toastr.success('クリップボードにコピーしました');
});
