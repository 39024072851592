import lottie from 'lottie-web/build/player/lottie_light';

document.addEventListener('DOMContentLoaded', () => {
  const lottieElements = document.querySelectorAll('.js-lottie');

  Array.from(lottieElements).forEach((element) => {
    const anim = lottie.loadAnimation({
      container: element,
      renderer: 'svg',
      loop: element.dataset.lottieCount || true,
      autoplay: true,
      path: element.dataset.lottieJsonPath,
    });
    anim.addEventListener('complete', e => { element.classList.add("d-none"); } );
  })
});
