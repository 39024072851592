import $ from 'jquery';

const showModalWithRemoteUrl = (event) => {
  const { currentTarget } = event;
  $('.js-remote-modal-title').html(currentTarget.dataset.title);
  const $modalBody = $('#js-remote-modal').find('.modal-body');
  $modalBody.html('<p class="text-center"><i class="fas fa-spinner fa-spin fa-5x"></i></p><p class="text-center">Loading...</p>');
  const url = currentTarget.dataset.url;
  $modalBody.load(url);
};

$(document).on('click', '.js-open-remote-modal', showModalWithRemoteUrl);
